import { isEmpty, omit, omitBy } from 'lodash';

export * from './strategies';

export class SearchEngine {
  constructor(strategy) {
    this.strategy = strategy;
  }

  search(searchTerm, facets) {
    return this.strategy.search(searchTerm, facets);
  }

  static extractSelectedFacetsFromQueryParams(queryParams, facetKeys) {
    return Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (!facetKeys.includes(key)) return acc;

      acc[key] = value.split(',').map(v => decodeURIComponent(v));
      return acc;
    }, {});
  }

  static removeFacetValueFromQueryParams(queryParams, facetKey, facetValue) {
    const newFacetValue = (queryParams[facetKey] || '').split(',').filter(value => value !== encodeURIComponent(facetValue));
    return this.updateFacetValueInQueryParams(queryParams, facetKey, newFacetValue);
  }

  static updateFacetValueInQueryParams(queryParams, facetKey, facetValue) {
    return omitBy({
      ...queryParams,
      [facetKey]: facetValue.map(v => encodeURIComponent(v)).join(','),
    }, isEmpty);
  }

  static updateSearchTermInQueryParams(queryParams, searchTerm) {
    return omitBy({
      ...queryParams,
      searchTerm,
    }, isEmpty);
  }

  static clearFacetsInQueryParams(queryParams, facetKeys) {
    return omit(queryParams, facetKeys);
  }
}
