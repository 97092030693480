<i18n lang="yaml">
pt:
  continueWithoutSaving: "Continuar sem salvar"
  createFirstPage: "Crie sua primeira página"
  needToSaveChanges: "Para que as alterações feitas estejam disponíveis você precisa salvá-las. Deseja salvar e continuar?"
  pageNotFound: "Página não encontrada"
  saveAndContinue: "Salvar e continuar"
  unsavedChanges: "Existem alterações não salvas na sua página"
en:
  continueWithoutSaving: "Continue without saving"
  createFirstPage: "Create your first page"
  needToSaveChanges: "For the changes to take effect you need to save them. Do you want to save and continue?"
  pageNotFound: "Page not found"
  saveAndContinue: "Save and continue"
  unsavedChanges: "There are unsaved changes in your page"
</i18n>
<template>
  <StudioViewsLayout>
    <FakeBrowser v-if="showView">
      <HeaderSection
        :view="editingView"
        :page-state-component-key="selectedComponentKey"
        class="builder-content__inner builder-content__inner--header"
      />
      <FilterToolbar class="builder-content__inner builder-content__inner--toolbar" />
      <CardEditor
        v-if="['Cards','Form'].includes(editingView.page_type)"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        @data-changed="updateData"
      />
      <TableEditor
        v-else-if="editingView.page_type === 'Table'"
        class="builder-content__inner builder-content__inner--table"
        @data-changed="updateData"
      />
      <TimelineEditor
        v-else-if="editingView.page_type === 'Timeline'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        @data-changed="updateData"
      />
      <ContentEditor
        v-else-if="editingView.page_type === 'Content'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        limited-width
      />
      <ReportEditor
        v-else-if="editingView.page_type === 'Report'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        limited-width
      />
      <KanbanEditor
        v-else-if="editingView.page_type === 'Kanban'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        @data-changed="updateData"
      />
      <MatrixEditor
        v-else-if="editingView.page_type === 'Matrix'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
        @data-changed="updateData"
      />
      <TreeEditor
        v-else-if="editingView.page_type === 'Tree'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
      />
      <CalendarEditor
        v-else-if="editingView.page_type === 'Calendar'"
        :record-id="currentRecord.id"
        class="builder-content__inner"
      />
    </FakeBrowser>
    <LogoLoading v-else-if="loadingView" />
    <v-container
      v-else-if="pageNotFound"
      class="fill-height"
    >
      <v-row
        align="center"
        justify="center"
        class="flex-column"
      >
        <div class="text-center">
          <img
            src="public/img-working.svg"
            alt="error"
            class="my-10"
            style="max-height: 300px; width: 100%"
          >
        </div>
        <div class="mb-2">
          {{ t('pageNotFound') }}
        </div>
      </v-row>
    </v-container>

    <template #right-panel>
      <transition
        name="slide-x-reverse-transition"
        mode="out-in"
      >
        <PageStateComponentsConfigPopup
          v-if="popupOpen"
          :key="currentInfoComponentId"
          class="studio-page__config-popup"
        />
      </transition>

      <ViewsOptions
        v-if="showView"
        :current-record="currentRecord"
        @data-changed="updateData"
      />
    </template>
  </StudioViewsLayout>
</template>

<script lang="ts">
import { v4 } from 'uuid';
import { mapMutations, mapState, mapGetters, mapActions } from '~/assets/javascript/modules/vuex';
import LogoLoading from '~/components/LogoLoading';
import confetti from 'canvas-confetti';
import FilterToolbar from '~/components/filter-toolbar';
import HeaderSection from '~/components/views/header-section';
import devPerformanceTrackingMixin from '~/mixins/devPerformanceTrackingMixin';
import PageStateComponentsConfigPopup from '~/components/views/page-state-components/config-popup';
import ViewsOptions from '~/components/studio/ViewsOptions';
import FakeBrowser from '~/components/studio/FakeBrowser';
import StudioViewsLayout from '~/components/studio/views/layout';

export default defineComponent({
  name: 'PagesTenantStudioPagesShow',
  components: {
    CalendarEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/CalendarEditor')),
    CardEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/CardEditor')),
    ContentEditor: defineAsyncComponent(() => import('~/components/views/content/ContentEditor')),
    FakeBrowser,
    FilterToolbar,
    HeaderSection,
    KanbanEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/KanbanEditor')),
    LogoLoading,
    MatrixEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/MatrixEditor')),
    PageStateComponentsConfigPopup,
    ReportEditor: defineAsyncComponent(() => import('~/components/views/report/ReportEditor')),
    StudioViewsLayout,
    TableEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/TableEditor')),
    TimelineEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/TimelineEditor')),
    TreeEditor: defineAsyncComponent(() => import('~/components/studio/views/editor/TreeEditor')),
    ViewsOptions,
  },
  mixins: [devPerformanceTrackingMixin],
  setup() {
    definePageMeta({
      layout: 'studio',
      key: 'pages',
      middleware: ['auth', 'refresh-user', 'is-admin'],
    });

    const { $apiClient, $errorRescue } = useNuxtApp();
    const { t } = useI18n();
    const vuex = {
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('builderView', ['hasChanges']),
      ...mapGetters('recordList', ['records']),
      ...mapActions('records', ['buildNewRecord']),
      ...mapGetters('pageStateComponents', ['popupOpen']),
      ...mapState('pageStateComponents', ['currentInfoComponentId']),
      ...mapState('viewOptions', ['sheetsCache']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapState('studio', ['contentExpanded']),
      ...mapState('view', ['view']),
      ...mapState('builderView', ['editingView', 'selectedComponentKey', 'views']),
      ...mapMutations('view', ['clearView']),
      ...mapMutations('builderView', ['setSelectedComponentKey', 'cleanEditingView', 'addFakeRecordId']),
      ...mapActions('workspace', ['loadWorkspace', 'setCategoryByViewId', 'firstViewIdFromViews']),
      ...mapActions('builderView', ['loadBuilderView']),
      ...mapActions('viewOptions', ['fetchSheetsCache']),
    };

    buildHead({
      title: computed(() => vuex.view.value.page_name || t('createFirstPage')),
    });

    useConfirmLeave({
      saveOnLeave: true,
      warnRouteLeave(to, from) {
        return vuex.hasChanges.value && (to.name !== from.name || to.params.id !== from.params.id);
      },
      warnPageClose() {
        return vuex.hasChanges.value;
      },
      async saveMethod() {
        try {
          await $apiClient.builder.views.publish(vuex.editingView.value.id);
          confetti();
        } catch (error) {
          $errorRescue(getCurrentInstance(), error, 'Error publishing view version');
        }
      },
    });

    return {
      t,
      ...vuex,
    };
  },
  data() {
    return {
      loadingView: true,
      pageNotFound: false,
    };
  },
  computed: {
    showView() {
      return (this.view.sandbox_view_id && this.editingView.id)
      && this.view.sandbox_view_id === this.editingView.id
      && this.view.id === this.$route.params.id;
    },
    currentRecord() {
      if (!this.$route.query.record_id) {
        // give a default record to avoid errors with props validation
        // it is necessary because the fake record could not be present yet
        return this.records[0] || { id: v4() };
      }

      return this.records.find(({ id }) => id === this.$route.query.record_id);
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === from.name && to.params.id !== from.params.id) this.onViewChange();
    },
    async editingView() {
      if (Object.keys(this.editingView).length === 0 || (this.records || []).length > 0) return;

      // Create a fake record to show some data as example
      const newRecordId = await this.buildNewRecord({ view: this.editingView });
      this.addFakeRecordId(newRecordId);
    },
  },
  beforeMount() {
    this.fetch();
  },
  beforeUnmount() {
    this.cleanData();
  },
  methods: {
    fetch() {
      this.onViewChange();
    },
    async updateSheetsCache(viewData = {}) {
      if (viewData.id) {
        for (let i = 0; i < this.sheetsCache.length; i++) {
          let view = this.sheetsCache[i].views.find(({ id }) => id === viewData.id);

          if (view) {
            const { id, ...updatedData } = viewData;
            view = { ...view, updatedData };
            return;
          }
        }
      }

      await this.fetchSheetsCache();
    },
    // We had to remove this logic from the fetch method because it was causing a bug
    // where the view was not being loaded when the user navigated to the page
    async onViewChange() {
      const viewId = this.$route.params.id;

      if (!viewId) {
        await this.loadWorkspace({ builder: true, waitLoad: true });

        const firstViewId = await this.firstViewIdFromViews(this.views);

        if (firstViewId) {
          this.$router.push(this.localePath({
            name: 't-tenantSlug-studio-pages-id',
            params: {
              tenantSlug: this.$route.params.tenantSlug,
              id: firstViewId,
            },
          }));

          return;
        }
      }

      if (this.$route.query?.component_key) {
        this.setSelectedComponentKey(this.$route.query.component_key);
      } else {
        this.setSelectedComponentKey('general');
      }

      await this.updateData();
    },
    async updateData() {
      const initialViewId = this.$route.params.id;
      this.loadingView = true;
      this.pageNotFound = false;

      try {
        await this.updateSheetsCache();
        await this.loadWorkspace({ builder: true });
        const viewId = this.$route.params.id;

        if (viewId) {
          await this.setCategoryByViewId(viewId);

          // Fetch data from the selected view
          await this.loadBuilderView({ viewId });

          // Fetch columns sort cache and set default if it does not exist
          const sortCache = _get(
            this.$store.state.viewOptions.sortBy,
            this.editingView.id,
          );

          if (!sortCache) {
            this.$store.commit('viewOptions/saveSorting', {
              viewId: this.editingView.id,
              sortBy: [],
              sortDesc: [],
            });
          }
        } else {
          this.cleanData();
        }
      } catch (error) {
        if (error.response?.status === 404) {
          this.pageNotFound = true;
          this.cleanData();
        } else {
          this.$errorRescue(this, error, 'Error loading view');
        }
      } finally {
        if (initialViewId === this.$route.params.id) this.loadingView = false;
      }
    },
    cleanData() {
      this.cleanEditingView();
      this.clearView();
    },
  },
});
</script>
<style lang="scss">
.builder-content__inner {
  padding-inline: var(--z-the-landscape-outer-spacing);
}

.builder-content__inner--header {
  padding-top: var(--z-the-landscape-outer-spacing);
  padding-bottom: var(--z-the-landscape-outer-spacing);
}

.builder-content__inner--toolbar {
  flex: none;
  padding-bottom: 0;
}

.builder-content__inner--table {
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px;
}

.studio-page__config-popup {
  &.slide-x-reverse-transition-enter-active {
    transition-delay: 25ms !important;
    transition-duration: 125ms !important;
  }

  &.slide-x-reverse-transition-leave-active {
    transition-duration: 50ms !important;
  }
}
</style>
