import VuexPersist from 'vuex-persist';
import { get, set } from 'lodash';
import debounce from 'lodash/debounce';

export const LOCAL_STORAGE_KEY = 'vuexPersistV3';

const storesToPersist = {
  view: ['locale'],
  viewOptions: ['sortBy', 'sortDesc', 'filter', 'columnWidths'],
  viewsMenu: ['miniSidebar'],
  workspace: ['workspace', 'currentCategoryName', 'newVersion', 'currentScope'],
  apps: ['selectedAreasOfInterest', 'selectedAppsPerSelectedAreaOfInterest'],
};

const SAFELIST = Object.entries(storesToPersist).reduce((acc, [storeName, keys]) => {
  keys.forEach((key) => {
    acc.push(`${storeName}.${key}`);
  });

  return acc;
}, []);

const debounceSetItem = debounce((key, value) => {
  if (!window.localStorage) return;

  try {
    const parsedValue = JSON.parse(value);

    const finalValue = JSON.stringify(SAFELIST.reduce((acc, path) => {
      set(acc, path, get(parsedValue, path));
      return acc;
    }, {}));

    if (window.localStorage.getItem(key) === finalValue) return;

    window.localStorage.setItem(key, finalValue);
  } catch {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem('vuexPersistV1'); // Remove old keys
    window.localStorage.removeItem('vuexPersistV2'); // Remove old keys
  }
}, 1000);

export default defineNuxtPlugin({
  name: 'vuex-persist',
  dependsOn: ['store'],
  setup({ $store }) {
    new VuexPersist({
      key: LOCAL_STORAGE_KEY, // The key to store the state on in the storage provider.
      modules: Object.keys(storesToPersist),
      storage: {
        ...window.localStorage,
        // Keep this function defined to dont break vue persistence getItem
        getItem(key) {
          return window.localStorage?.getItem(key);
        },
        setItem(key, value) {
          debounceSetItem(key, value);
        },
      }, // or window.sessionStorage or localForage
    }).plugin($store);
  },
});
