<template>
  <div class="h-full">
    <p
      v-if="fetchData.viewLoadError"
      class="ma-8 pa-6 rounded bg-white"
    >
      {{ fetchData.viewLoadError }}
    </p>

    <TheLandscape
      v-else-if="showView"
      ref="landscape"
      :view="view"
      :fields="fetchData.columns"
      :can-edit="canEdit"
      :can-create="canCreate"
      :can-delete="canDelete"
      :search="search"
      :loading="pending"
      :skeleton="pending"
      :current-group-by-field="recordToolboxOptions.hasGroup ? recordToolboxOptions.currentGroupByField : null"
      class="h-full"
      comfort-read-mode
      page-state-component-key="index"
    />

    <LogoLoading v-else />
  </div>
</template>

<script lang="ts">
import LogoLoading from '~/components/LogoLoading';
import viewChannelMixin from '~/mixins/viewChannelMixin';
import { mapState, mapGetters } from '~/assets/javascript/modules/vuex';
import ROUTES, { isChangingBetweenRoutes } from '~/assets/javascript/constants/routes';

export default defineComponent({
  name: 'MemberViews',
  components: {
    LogoLoading,
    TheLandscape: defineAsyncComponent(() => import('~/components/views/the-landscape')),
  },
  mixins: [
    viewChannelMixin(),
  ],
  beforeRouteLeave(to, from) {
    if (!['Kanban', 'Matrix'].includes(this.view.page_type)) return true;

    if (this.isFeatureEnabled('singlePageKanban')) {
      // Prevent change route even if user clicks on a record
      // Kanban change route virtually when open/close dialog
      const preventNavigation = isChangingBetweenRoutes({
        from: from.name,
        to: to.name,
        routes: [
          ROUTES.member.view.routeName,
          ROUTES.member.view.record.routeName,
        ],
      }) && to.params.viewId === this.$route.params.viewId;

      if (preventNavigation) {
        return false;
      }

      return true;
    }

    // TODO: Delete this scroll fix after singlePageKanban is the main approach
    // TODO: with TheLandscape now able to render both indexes and records, we
    // should deal with saving scroll positions in a more generic way over there
    const routeNameWithoutI18n = to.name.split('___')[0];
    if (routeNameWithoutI18n !== ROUTES.member.view.record.routeName) return true;
    const contentElement = this.$refs.landscape?.$refs?.content?.$el;
    if (!contentElement) return true;

    const scrollPosition = {
      scrollTop: contentElement.scrollTop,
      scrollLeft: contentElement.scrollLeft,
    };

    window.sessionStorage.setItem('kanbanScrollPosition', JSON.stringify(scrollPosition));

    return true;
  },
  async setup() {
    definePageMeta({
      layout: 'member',
    });

    const vuex = {
      ...mapState('view', ['view']),
      ...mapGetters('view', ['canEdit', 'canCreate', 'canDelete']),
      ...mapGetters('recordList', ['recordToolboxOptions']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('records', ['hasChangesInDraftRecords']),
      ...mapGetters('features', ['isFeatureEnabled']),
    };

    useConfirmLeave({
      warnRouteLeave() {
        return vuex.hasChangesInDraftRecords.value;
      },
      warnPageClose() {
        return vuex.hasChangesInDraftRecords.value;
      },
    });

    const { view, showView } = useView();
    const { data: fetchData, status: fetchStatus } = await useLazyAsyncData(async () => {
      const route = useRoute();
      const { $api, $localePath } = useNuxtApp();
      const publicPages = await $api.$get(`/public/workspaces/${route.params.tenantSlug}/views`);

      const router = useRouter();
      const { $store } = useNuxtApp();

      // Check if the current viewId belongs to a public page
      if (publicPages?.some(page => page.id === route.params.viewId)) {
        return router.push($localePath({ name: ROUTES.public.view.routeName, params: { tenantSlug: route.params.tenantSlug } }));
      }

      const { viewLoadError, columns } = await useViewFetch({ autoRecordRedirect: true });

      if (viewLoadError) {
        return router.push($localePath({ name: ROUTES.member.views.routeName, params: { tenantSlug: route.params.tenantSlug } }));
      }

      $store.commit('viewsMenu/resetFilterToolbarSearch');
      $store.commit('table/initTable');

      useTrackAppUsage('index');
      return {
        columns,
        viewLoadError,
      };
    }, {
      default() {
        return {
          columns: [],
          viewLoadError: null,
        };
      },
    });

    return {
      fetchData,
      fetchStatus,
      view,
      showView,
      ...vuex,
    };
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    viewChannelMixin__view() {
      return this.view;
    },
    pending() {
      return this.fetchStatus === 'pending';
    },
  },
  async mounted() {
    // TODO: with TheLandscape now able to render both indexes and records, we
    // should deal with saving scroll positions in a more generic way over there
    if (this.isViewType('Kanban') && window.sessionStorage.getItem('kanbanScrollPosition')) {
      // We need to wait for computeds and refs to be ready
      // 3 nextTick was necessary because we use a dynamic component and all the states to be updated
      await nextTick();
      await nextTick();
      await nextTick();
      const scrollPosition = JSON.parse(window.sessionStorage.getItem('kanbanScrollPosition'));
      const contentElement = this.$refs.landscape?.$refs?.content?.$el;
      if (!contentElement) return;

      contentElement.scrollTop = scrollPosition.scrollTop;
      contentElement.scrollLeft = scrollPosition.scrollLeft;
      window.sessionStorage.removeItem('kanbanScrollPosition');
    }
  },
  methods: {
    isViewType(type) {
      return this.view.page_type === type;
    },
  },
});
</script>
