import RecordList, { populateSearchableData } from '~/assets/javascript/modules/record/list';
import RecordListOptions from '~/assets/javascript/modules/record/list/options';

export const state = () => ({
});

export const getters = {
  i18n:
    (_state, _getters) => useNuxtApp().$i18n,
  currentView:
    (_state, _getters, { view: viewState, studio: studioState, builderView: builderViewState }) => (
      studioState.inStudio ? builderViewState.view : viewState.view
    ),
  draftRecords:
    (_state, getters, { records: recordsState }) => getters
      .currentView
      ?.recordIds
      ?.map(id => recordsState.draftRecordsById[id])
      ?.filter(Boolean) || [],
  draftRecordsToView:
    (_state, _getters, { records: recordsState }) => view => view
      ?.recordIds
      ?.map(id => recordsState.draftRecordsById[id])
      ?.filter(Boolean) || [],
  // use a separated getter to avoid reactivity issues when searching data
  searchData: (_state, { draftRecords, filterByFieldId, groupByFieldId, recordToolboxOptions, i18n }) => {
    const emptyLabel = i18n.t('global.emptyLabel');
    const groupField = recordToolboxOptions.fieldIdMapping[groupByFieldId];
    const filterField = recordToolboxOptions.fieldIdMapping[filterByFieldId];

    return populateSearchableData(draftRecords, { groupField, filterField, emptyLabel });
  },
  recordToolbox:
    (_state, getters) => {
      const { $router } = useNuxtApp();

      return new RecordList({
        view: getters.currentView,
        records: getters.searchData,
        options: getters.recordToolboxOptions,
        emptyLabel: getters.i18n.t('global.emptyLabel'),
        queryParams: $router.currentRoute.value.query,
      });
    },
  recordToolboxOptions:
    (_state, getters) => new RecordListOptions(
      getters.currentView,
      getters.i18n?.locale,
    ),
  filterByFieldId: (_state, getters) => getters.recordToolboxOptions.filterByFieldId,
  groupByFieldId: (_state, getters) => getters.recordToolboxOptions.groupFieldId,
  records: (_state, getters) => getters.recordToolbox.records,
};

export const mutations = {
};

export const actions = {
};

export const namespaced = true;
