import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_H4uUvhyrU1 from "/codebuild/output/src34513713/src/zazos-web/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_KAumv2pUni from "/codebuild/output/src34513713/src/zazos-web/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src34513713/src/zazos-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vvOX7kxpKw from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt-rollbar/dist/runtime/plugin.mjs";
import http_plugin_nuxt_BmzkAX86rT from "/codebuild/output/src34513713/src/zazos-web/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import vuetify_no_client_hints_kyYn5AnnAK from "/codebuild/output/src34513713/src/zazos-web/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src34513713/src/zazos-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src34513713/src/zazos-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_plugin_6DDHMgc34l from "/codebuild/output/src34513713/src/zazos-web/.nuxt/auth.plugin.mjs";
import disable_click_outside_data_table_DAObdEOAI3 from "/codebuild/output/src34513713/src/zazos-web/plugins/directives/disable-click-outside-data-table.ts";
import test_class_oX1FvP5ebH from "/codebuild/output/src34513713/src/zazos-web/plugins/directives/test-class.ts";
import index_CWAQDg1Pd4 from "/codebuild/output/src34513713/src/zazos-web/plugins/error-rescue/index.ts";
import index_IcUpZG5ixa from "/codebuild/output/src34513713/src/zazos-web/plugins/segment-identify/index.ts";
import actioncable_vue_VgFqOLMR1t from "/codebuild/output/src34513713/src/zazos-web/plugins/actioncable-vue.ts";
import api_GFfDXud5Cr from "/codebuild/output/src34513713/src/zazos-web/plugins/api.ts";
import index_lFm5uFWT5Z from "/codebuild/output/src34513713/src/zazos-web/plugins/async-data-manager/index.js";
import auth_vT9JWWT9pN from "/codebuild/output/src34513713/src/zazos-web/plugins/auth.ts";
import i18n_VfGcjrvSkj from "/codebuild/output/src34513713/src/zazos-web/plugins/i18n.ts";
import notifier_hdhOsy29uv from "/codebuild/output/src34513713/src/zazos-web/plugins/notifier.ts";
import portal_vue_h7YCDNO0gl from "/codebuild/output/src34513713/src/zazos-web/plugins/portal-vue.ts";
import index_rUJILaokoq from "/codebuild/output/src34513713/src/zazos-web/plugins/recaptcha/index.ts";
import rollbar_production_gv8n0haoxn from "/codebuild/output/src34513713/src/zazos-web/plugins/rollbar-production.ts";
import scroll_S7pZiC3d46 from "/codebuild/output/src34513713/src/zazos-web/plugins/scroll.ts";
import v_hotkey_KMTQitDbl3 from "/codebuild/output/src34513713/src/zazos-web/plugins/v-hotkey.ts";
import vue_moment_rHm0QbnoOf from "/codebuild/output/src34513713/src/zazos-web/plugins/vue-moment.ts";
import vue_sanitize_b6XA76Qotp from "/codebuild/output/src34513713/src/zazos-web/plugins/vue-sanitize.ts";
import vuetify_mask_v160J0pPs5 from "/codebuild/output/src34513713/src/zazos-web/plugins/vuetify-mask.ts";
import vuetify_7h9QAQEssH from "/codebuild/output/src34513713/src/zazos-web/plugins/vuetify.ts";
import index_jS2b3VkzVy from "/codebuild/output/src34513713/src/zazos-web/plugins/vuex-persist/index.ts";
import vuex_F8JCgj45hr from "/codebuild/output/src34513713/src/zazos-web/plugins/vuex.ts";
import wait_until_uyKI4v4vDW from "/codebuild/output/src34513713/src/zazos-web/plugins/wait-until.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/codebuild/output/src34513713/src/zazos-web/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_H4uUvhyrU1,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_vvOX7kxpKw,
  http_plugin_nuxt_BmzkAX86rT,
  vuetify_no_client_hints_kyYn5AnnAK,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  auth_plugin_6DDHMgc34l,
  disable_click_outside_data_table_DAObdEOAI3,
  test_class_oX1FvP5ebH,
  index_CWAQDg1Pd4,
  index_IcUpZG5ixa,
  actioncable_vue_VgFqOLMR1t,
  api_GFfDXud5Cr,
  index_lFm5uFWT5Z,
  auth_vT9JWWT9pN,
  i18n_VfGcjrvSkj,
  notifier_hdhOsy29uv,
  portal_vue_h7YCDNO0gl,
  index_rUJILaokoq,
  rollbar_production_gv8n0haoxn,
  scroll_S7pZiC3d46,
  v_hotkey_KMTQitDbl3,
  vue_moment_rHm0QbnoOf,
  vue_sanitize_b6XA76Qotp,
  vuetify_mask_v160J0pPs5,
  vuetify_7h9QAQEssH,
  index_jS2b3VkzVy,
  vuex_F8JCgj45hr,
  wait_until_uyKI4v4vDW,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]