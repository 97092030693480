import { default as indexkj4ZSTt8M0Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/index.vue?macro=true";
import { default as indexhVsA48Q9MJMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexvia8KzzWBJMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetwwoZpIgWg2Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexxv0XmX3YhfMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspace4DnJbQcLJuMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as indexQ6YJUn8PMyMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93vvn5l3eifiMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexD1pQF3Ak2oMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexGEzRKWZMZaMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as index2ZGdmcu446Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexxhKwb1IKNsMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as index1ixhViYLVjMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexQRdgHt2NlsMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indextgvDbuY2eSMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newrxGZEqcnyyMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationQa2E638H9fMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexk5RswBNqV6Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mrBHYlXCVC2Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as index3FCKusJwc1Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as indexCt23ydB2KLMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexFFOvUP4RzcMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordfc0qMnYf2RMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indextieXXr67jeMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexhA2zsdja8JMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyXwo8099mVyMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexozXTPFQsBhMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexKeAWxWoy2hMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importCRITziZYB3Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexNz5i15xaWOMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemaWTNZu2yQ6JMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indextjIprX6RxsMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as index07RuWjmPgUMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_93YsjryuGRd0Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as index07GPFfqApnMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexlaLU40JrJuMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexZifiQtAONkMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexiFIlAXOLnxMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexL4TNf2lNzEMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93vU4WnkWzBfMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as indexq379kaoauVMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsFcjubxouwZMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as index3YxWff9OMHMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as index18B6GAJCQHMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as indextJ8hnereX0Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93mNs9HDXUC3Meta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexpIZr0EJZZFMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newXhCCVCO4qCMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupstyKAzp0StoMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as indexIDirzazNfbMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingsjtkAo63CAGMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studioWFFQbpvwOhMeta } from "/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexkj4ZSTt8M0Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexkj4ZSTt8M0Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexhVsA48Q9MJMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    meta: indexhVsA48Q9MJMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    meta: indexvia8KzzWBJMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    meta: indexvia8KzzWBJMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: indexxv0XmX3YhfMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    meta: indexxv0XmX3YhfMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    meta: set_45workspace4DnJbQcLJuMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    meta: set_45workspace4DnJbQcLJuMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93vvn5l3eifiMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93vvn5l3eifiMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexD1pQF3Ak2oMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexD1pQF3Ak2oMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexGEzRKWZMZaMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexGEzRKWZMZaMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: index2ZGdmcu446Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: index2ZGdmcu446Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexxhKwb1IKNsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexxhKwb1IKNsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: index1ixhViYLVjMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: index1ixhViYLVjMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mrBHYlXCVC2Meta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mrBHYlXCVC2Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexQRdgHt2NlsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indextgvDbuY2eSMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newrxGZEqcnyyMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationQa2E638H9fMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexk5RswBNqV6Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mrBHYlXCVC2Meta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mrBHYlXCVC2Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexQRdgHt2NlsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indextgvDbuY2eSMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newrxGZEqcnyyMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationQa2E638H9fMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexk5RswBNqV6Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: index3FCKusJwc1Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: index3FCKusJwc1Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexCt23ydB2KLMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexCt23ydB2KLMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexFFOvUP4RzcMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexFFOvUP4RzcMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordfc0qMnYf2RMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordfc0qMnYf2RMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indextieXXr67jeMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indextieXXr67jeMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studioWFFQbpvwOhMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studioWFFQbpvwOhMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyXwo8099mVyMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexozXTPFQsBhMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexKeAWxWoy2hMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importCRITziZYB3Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexNz5i15xaWOMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemaWTNZu2yQ6JMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indextjIprX6RxsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_93YsjryuGRd0Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: index07GPFfqApnMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsjtkAo63CAGMeta?.name,
    path: "settings",
    meta: settingsjtkAo63CAGMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsFcjubxouwZMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupstyKAzp0StoMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studioWFFQbpvwOhMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studioWFFQbpvwOhMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyXwo8099mVyMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexozXTPFQsBhMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexKeAWxWoy2hMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importCRITziZYB3Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexNz5i15xaWOMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemaWTNZu2yQ6JMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indextjIprX6RxsMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_93YsjryuGRd0Meta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: index07GPFfqApnMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsjtkAo63CAGMeta?.name,
    path: "settings",
    meta: settingsjtkAo63CAGMeta || {},
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsFcjubxouwZMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupstyKAzp0StoMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src34513713/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]