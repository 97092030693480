import validate from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src34513713/src/zazos-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-redirect": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/auth-redirect.ts"),
  "is-admin": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/is-admin.ts"),
  "product-fruits": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/product-fruits.js"),
  "refresh-user": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/refresh-user.ts"),
  "save-recent-navigation": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/save-recent-navigation.js"),
  "slug-redirect": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/slug-redirect.js"),
  "workspace-loading": () => import("/codebuild/output/src34513713/src/zazos-web/middleware/workspace-loading.js"),
  auth: () => import("/codebuild/output/src34513713/src/zazos-web/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs")
}